.forgot-password .shadow-box {
    height: 400px;
}

.forgot-password form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 80%;
    width: 400px;
}

.forgot-password form .heading {
    font-size: 18px;
    padding-bottom: 28px;
}

.completed {
    justify-content: flex-start !important;
}

.completed p {
    margin-top: 50px;
    font-size: 15px !important;
    letter-spacing: 0.4px;
}
