.upload {
    width: 100%;
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bg-primary {
    background-color: #b12418 !important;
}

.font-white {
    color: white !important;
}

.shadow-box h2 {
    font-weight: 500 !important;
    margin-bottom: 20px;
    color: rgb(41, 40, 40);
}

.text-cont {
    color: rgb(150, 148, 148);
    font-weight: 500;
}

.upload > img {
    width: 230px;
    margin-bottom: 50px;
}

.backBtn {
    position: absolute;
    top: 30px;
    left: 40px;
    box-shadow: none;
    border: 0px;
    background-color: transparent;
    z-index: 10;
}

.upload > .shadow-box {
    position: relative;
    width: 68vw;
    min-height: 500px;
    flex-direction: column;
    padding: 28px;
    border-radius: 18px;
    background-color: rgb(250, 250, 250);
    box-shadow: rgba(0, 0, 0, 0.08) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 50px;
    height: 400px;
}

.csvName-cont {
    max-width: 50%;
}
.csvName-cont span {
    font-size: 14px;
    margin-left: 10px;
}

.csvName-cont .btn {
    margin-top: 20px;
    outline: none;
    padding: 10px;
    background-color: transparent;
    border: 0px !important;
    color: blue;
    font-weight: 500;
}

.button-cont {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin-top: 50px;
}

.button-cont .btn1 {
    padding: 8px 90px;
    border: 0px;
    border-radius: 10px;
    font-size: 20px;
    color: white;
}

.button-cont .btn2 {
    padding: 8px 60px;
    border: 0px;
    border-radius: 10px;
    font-size: 20px;
    margin-left: 60px;
    background-color: white;
    box-shadow: 0px 0px 12px 3px #ccc;
    color: rgb(163, 160, 160);
    font-weight: 500;
}

.progress-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 50px;
}

.status {
    position: absolute;
    text-align: center;
    font-size: 14px;
}
.step1 {
    left: -22px;
    top: 26px;
}
.step2 {
    left: 94px;
    top: 26px;
}
.step3 {
    left: 186px;
    top: 26px;
}
.step4 {
    left: 314px;
    top: 26px;
}
.outer-circle {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid rgb(150, 147, 147);
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: rgb(150, 147, 147);
}

.line {
    width: 100px;
    height: 4px;
    background-color: rgb(224, 221, 221);
}

.line:last-child {
    display: none;
}

@media screen and (max-width: 1320px) {
    .shadow-box {
        width: 85vw !important;
        height: auto !important;
        max-height: 800px !important;
    }
}

@media screen and (max-width: 700px) {
    .desktop-cont {
        display: none;
    }

    .mobile-cont p {
        margin-bottom: 20px;
    }
    .button-container {
        display: none;
    }
    .button-cont .btn1 {
        padding: 8px 30px;
        border: 0px;
        border-radius: 10px;
        font-size: 20px;
        color: white;
    }
}
