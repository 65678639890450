.prompt-page {
    font-size: 12px;
    position: absolute;
    top: 5px;
    right: -25px;
    color: red;
}

h3 {
    font-size: 20px;
}

form {
    width: 100%;
}

.question {
    padding: 14px 20px 50px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.question label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.question .checkBox {
    height: 30px;
    width: 30px;
    margin-right: 0px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.question p {
    letter-spacing: 0.4px;
    font-size: 17px;
}
.beginBtnDisabled {
    background-color: #fff;
    color: rgb(91, 91, 91);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 0px;
    padding: 14px 70px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.4px;
}
