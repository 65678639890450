.container {
    color: rgb(150, 148, 148);
    font-weight: 500;
    width: 60%;
}

.button-conta {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin-top: 50px;
}

.downloadBtn {
    padding: 10px 0px;
    width: 220px;
    border: 0px;
    border-radius: 10px;
    font-size: 20px;
    color: white;
    margin-left: 20px;
}
