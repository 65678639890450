.home {
    width: 100%;
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home > img {
    width: 230px;
    margin-bottom: 50px;
}

.home a {
    color: blue !important;
}

.shadow-box {
    width: 68vw;
    padding: 30px;
    border-radius: 18px;
    background-color: rgb(250, 250, 250);
    box-shadow: rgba(0, 0, 0, 0.08) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    height: 400px;
}

.desktop-cont {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
}

.mobile-cont {
    display: none;
}

.textSide {
    text-align: left;
    margin-left: 18px;
}

.textSide h1 {
    font-weight: 400;
}

.textSide h3 {
    font-weight: 600;
    margin: 16px 0px;
    font-size: 16px;
    color: rgb(91, 91, 91);
}

.input-cont {
    display: flex;
    align-items: center;
}

.input-cont input {
    box-shadow: rgba(0, 0, 0, 0.08) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    border: 0px;
    border-radius: 10px;
    padding: 12px 22px;
    margin: 20px 0px;
    font-weight: 600;
    font-size: 20px;
}
.input-cont p {
    padding-left: 20px;
    font-weight: 500;
    font-size: 14px;
    color: red;
}

.access-code-input::placeholder {
    font-weight: 500;
    color: rgb(192, 189, 189);
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
}

.button-container {
    width: 380px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.08) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    border-radius: 12px;
    padding: 8px 12px 8px 0px;
    justify-content: space-between;
}

.button-container button {
    font-size: 20px;
    background-color: white;
    border: none;
    color: rgb(91, 91, 91);
}

.button-container img {
    height: 50px;
    margin: 0px 4px;
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color: transparent;
    outline: none;
    border: 0px;
}

.checkBox {
    height: 21px;
    width: 21px;
    box-shadow: 0px 0px 6px 2px #ccc;
    border-radius: 4px;
    flex-shrink: 0;
}

.check-bg {
    background-color: #b12418;
}

.begin-btn-cont {
    display: flex;
    align-items: center;
}

.beginBtn {
    border: 0px;
    background-color: white;
    padding: 14px 70px;
    border-radius: 10px;
    background-color: #b12418;
    color: white;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.4px;
}

.beginBtn:disabled {
    background-color: #fff;
    color: rgb(91, 91, 91);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.begin-btn-cont p {
    padding-left: 20px;
    font-weight: 500;
    font-size: 14px;
    color: red;
}

@media screen and (max-width: 1100px) {
    .shadow-box {
        width: 85vw;
    }
}

@media screen and (max-width: 1090px) {
    .home > img {
        width: 75%;
    }

    .button-container {
        width: 95%;
    }

    .button-container button {
        font-size: 16px;
    }

    .button-container img {
        height: 30px;
    }

    .shadow-box {
        padding: 20px;
        width: calc(95% - 20px * 2);
    }

    .shadow-box span {
        font-size: 16px;
    }

    .shadow-box p {
        font-size: 13px;
    }
    .desktop-cont {
        display: none;
    }
    .mobile-cont {
        display: flex;
        flex-direction: column;
        color: rgb(91, 91, 91);
        margin-bottom: 50px;
    }
    .mobile-cont p {
        margin-bottom: 20px;
    }
    .button-container {
        display: none;
    }
}
