:root {
    --primary-color: #c00100;
    --secondary-color: #000000;
    --border-color: #cccccc;
    --highlight-color: #5f5f5f;
}

* {
    margin: 0px;
    padding: 0px;
    font-family: "Montserrat", sans-serif !important;
    outline: none;
}

body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#root {
    width: 1200px;
}

@media screen and (max-width: 1200px) {
    #root {
        width: 95%;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

a,
button {
    cursor: pointer;
}

ul {
    list-style: none;
}

.ml0 {
    margin-left: 4px;
}

.ml1 {
    margin-left: 8px;
}

.ml2 {
    margin-left: 12px;
}

.ml3 {
    margin-left: 16px;
}

.ml4 {
    margin-left: 20px;
}

.ml5 {
    margin-left: 24px;
}

.ml6 {
    margin-left: 28px;
}

.mb0 {
    margin-bottom: 4px;
}

.mb1 {
    margin-bottom: 8px;
}

.mb2 {
    margin-bottom: 12px;
}

.mb3 {
    margin-bottom: 16px;
}

.mb4 {
    margin-bottom: 20px;
}

.mb5 {
    margin-bottom: 24px;
}

.mb6 {
    margin-bottom: 28px;
}

.mr0 {
    margin-right: 4px;
}

.mr1 {
    margin-right: 8px;
}

.mr2 {
    margin-right: 12px;
}

.mr3 {
    margin-right: 16px;
}

.mr4 {
    margin-right: 20px;
}

.mr5 {
    margin-right: 24px;
}

.mr6 {
    margin-right: 28px;
}

.mr0 {
    margin-right: 4px;
}

.mt1 {
    margin-top: 8px;
}

.mt2 {
    margin-top: 12px;
}

.mt3 {
    margin-top: 16px;
}

.mt4 {
    margin-top: 20px;
}

.mt5 {
    margin-top: 24px;
}

.mt6 {
    margin-top: 28px;
}

.pl0 {
    padding-left: 4px;
}

.pl1 {
    padding-left: 8px;
}

.pl2 {
    padding-left: 12px;
}

.pl3 {
    padding-left: 16px;
}

.pl4 {
    padding-left: 20px;
}

.pl5 {
    padding-left: 24px;
}

.pl6 {
    padding-left: 28px;
}

.pb0 {
    padding-bottom: 4px;
}

.pb1 {
    padding-bottom: 8px;
}

.pb2 {
    padding-bottom: 12px;
}

.pb3 {
    padding-bottom: 16px;
}

.pb4 {
    padding-bottom: 20px;
}

.pb5 {
    padding-bottom: 24px;
}

.pb6 {
    padding-bottom: 28px;
}

.pr0 {
    padding-right: 4px;
}

.pr1 {
    padding-right: 8px;
}

.pr2 {
    padding-right: 12px;
}

.pr3 {
    padding-right: 16px;
}

.pr4 {
    padding-right: 20px;
}

.pr5 {
    padding-right: 24px;
}

.pr6 {
    padding-right: 28px;
}

.pr0 {
    padding-right: 4px;
}

.pt1 {
    padding-top: 8px;
}

.pt2 {
    padding-top: 12px;
}

.pt3 {
    padding-top: 16px;
}

.pt4 {
    padding-top: 20px;
}

.pt5 {
    padding-top: 24px;
}

.pt6 {
    padding-top: 28px;
}

.in-row,
.in-column,
.align-center,
.justify-center,
.justify-end,
.justify-sb,
.justify-start,
.align-end,
.align-start {
    display: flex;
}

.align-end {
    align-items: end;
}

.justify-start {
    justify-content: start;
}

.align-start {
    align-items: start;
}

.in-row {
    flex-direction: row;
}

.in-column {
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-sb {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.width100 {
    width: 100% !important;
}

.pointer {
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed;
}

.italic {
    font-style: italic;
}

.ft0 {
    font-size: 10px;
}

.ft1 {
    font-size: 12px;
}

.ft2 {
    font-size: 14px;
}

.ft3 {
    font-size: 18px;
}

.ft4 {
    font-size: 22px;
}

.ft5 {
    font-size: 26px;
}

.ft6 {
    font-size: 30px;
}

.fw0 {
    font-weight: 200;
}
.fw1 {
    font-weight: 300;
}
.fw2 {
    font-weight: 400;
}
.fw3 {
    font-weight: 500;
}
.fw4 {
    font-weight: 600;
}
.fw5 {
    font-weight: 700;
}
.fw6 {
    font-weight: 800;
}

.text-center {
    text-align: center !important;
}

.text-start {
    text-align: start !important;
}

.main-wrapper {
    margin: 5px;
    border: 2px solid var(--primary-color);
    width: calc(100% - 14px);
    height: 408px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 4px;
}

.primary-button {
    width: 180px !important;
    height: 36px;
    border-radius: 9px;
    border: none;
    background: none;
    background-color: var(--primary-color);
    color: white;
    text-transform: capitalize;
    font-size: 13.57;
}

.form-container {
    display: flex;
    width: 90%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.form-container > * {
    width: 95%;
}

.form-container p {
    width: 100%;
    font-size: 8.57px;
    text-align: center;
}

.form-container h3 {
    font-weight: 400;
    font-size: 18px;
    text-align: center;
}

.form-container .inputs {
    width: 96%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-container .heading {
    width: 100%;
    font-size: 20px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 12px;
}

button:disabled {
    opacity: 0.7;
}

.text-highlight {
    color: var(--highlight-color);
    letter-spacing: 1.1px;
}

.underline {
    text-decoration: underline;
}

.border-bottom {
    border-bottom: 1px solid var(--border-color);
}

.image {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 2px solid white;
}

.image.hover-effect:hover {
    border-color: var(--primary-color);
}
