.select-entries-table-container {
    width: calc(100% - 24px * 2);
    max-height: 250px;
    overflow-x: scroll;
    overflow-y: scroll;
    box-shadow: rgb(0 0 0 / 8%) 3px 3px 6px 0px inset, rgb(255 255 255 / 50%) -3px -3px 6px 1px inset;
    border-radius: 24px;
    padding: 24px;
    margin: 22px 0px;
    font-size: 11px;
}

.select-entries-table {
    padding: 12px 5px;
    overflow: scroll;
    width: 100%;
    height: 100%;
    border-spacing: 0;
}

.select-entries-table tr:last-child td {
    border-bottom: 0;
}

.select-entries-table th,
.select-entries-table td {
    min-width: 120px;
    margin: 0;
    padding: 0.2rem;
    border-bottom: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
}

.select-entries-table th:last-child,
.select-entries-table td:last-child {
    border-right: 0;
}

.selected-column {
    background-color: rgb(193, 207, 177);
}

.selected-value {
    background-color: rgb(162, 186, 135);
}

.error-message {
    font-weight: 500;
    font-size: 14px;
    color: red;
}
