.input-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    justify-content: space-between;
}


.input-wrap .input {
    max-width: 190px;
    display: flex;
    flex-direction: column;
}

.input-wrap label {
    letter-spacing: 1.24px;
    font-size: 13px;
    padding-top: 4px;
}

.input-wrap small {
    font-size: 11px;
    color: var(--primary-color);
    margin-top: 4px;
    height: 12px;
}

.input-wrap input {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    width: 100%;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--border-color);
    font-size: 14px;
}

.input-wrap.error input {
    border-color: var(--primary-color);
    color: var(--primary-color);
}
