header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.header-inner {
    width: 70vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px 0px 0px;
    color: #c1c1c1 !important;
    font-size: 20px !important;
}

.header-inner .links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.header-inner img {
    height: 50px;
    margin-right: 100px;
}

@media screen and (max-width: 900px) {
    .header-inner {
        width: 95%;
        font-size: 16px !important;
    }
}

@media screen and (max-width: 700px) {
    .header-inner {
        justify-content: center;
        flex-direction: column;
    }

    .header-inner img {
        width: 220px;
        height: auto;
        margin: 0px;
        margin-bottom: 22px;
    }

    .header-inner .links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
    }
}
