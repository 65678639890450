.name-input {
    padding: 12px 10px;
    font-size: 18px;
    border: 0px;
    box-shadow: rgba(0, 0, 0, 0.08) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    border-radius: 10px;
    margin: 34px 0px;
    text-align: center;
    font-weight: 500;
    width: 40%;
}

.name-input::placeholder {
    color: gray;
}
