.text-page,
.container {
    width: 100%;
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-page > img,
.container > img {
    width: 250px;
    margin-bottom: 50px;
}

.text-page .shadow-box {
    padding: 40px 60px;
    width: calc(68vw - 60px);
    white-space: pre-line;
    display: unset;
    text-align: left;
    height: 460px;
}

.text-page .shadow-box p {
    height: 100%;
    overflow: scroll;
    white-space: pre-line;
    text-align: left;
}

@media screen and (max-width: 700px) {
    .text-page > img,
    .container > img {
        width: 52%;
    }
}
